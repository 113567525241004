@if (profilePictureURL()) {
  <p-image
    imageClass="rounded-full object-contain size-full bg-[#f5fbff]"
    tooltipStyleClass="text-sm"
    [alt]="fullName()"
    [pTooltip]="showTooltip() ? fullName() : undefined"
    [src]="profilePictureURL()"
  />
} @else {
  <div
    class="inline-flex size-full min-h-6 min-w-6 items-center justify-center overflow-hidden rounded-full border border-primary-100 bg-primary-50"
    tooltipPosition="top"
    tooltipStyleClass="text-sm"
    [pTooltip]="showTooltip() ? fullName() : undefined"
  >
    <span class="text-primary">
      {{ rawText() ? rawText() : initials() }}
    </span>
  </div>
}
